import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import ConnectorRedirect from "./pages/Redirect";
import Privacy from "./pages/Privacy";

import { Amplify } from "aws-amplify";
import Authenticator from "./components/Auth/Authenticator";

import awsConfig from "./aws-exports";
Amplify.configure(awsConfig);

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={ConnectorRedirect} />
          <Route exact path="/privacy" component={Privacy} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default Authenticator(App);
