import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AuthState } from '@aws-amplify/ui-components';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ConfirmSignUp(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="" width="80" heigth="80" src={require('../../assets/GoWizi_RGB Minimum Reduction Full Color in Light Background.png')} />
        <Typography component="h1" variant="h5">
          Verificação de E-mail
        </Typography>
        <form className={classes.form} noValidate onSubmit={props.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="username"
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username (sem espaços)"
                autoFocus
                value={props.username}
                onChange={props.handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="code"
                label="Código de Verificação"
                name="code"
                value={props.code}
                onChange={props.handleVCChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Confirmar
              </Button>
            </Grid>
            <Grid container>
                <Grid item xs>
                <Link href="#" variant="body2" onClick={props.resendCode}>
                    Re-enviar código
                </Link>
                </Grid>
                <Grid item>
                    <Link href="#" variant="body2" onClick={ () => { props.changeAuthState( AuthState.SignIn )}}>
                        Volta para a página de Login
                    </Link>
                </Grid>
            </Grid>
        </form>
      </div>
    </Container>
  );
}

