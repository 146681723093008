/* eslint-disable */

const awsConfig = {
  dev: {
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id:
      "eu-west-1:df2b7339-2b90-45d8-8634-01f11805e8da",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_btmhokVoV",
    aws_user_pools_web_client_id: "1j26sdjpgv9r9h2ssgd46q5g5q",
    mandatorySignIn: false,
    oauth: {},
    aws_appsync_graphqlEndpoint:
      "https://7xztir7cv5azbohcj6vq2rkqke.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_content_delivery_bucket:
      "portalstack-hostingbucket5dac2127-1s0nv3qzla8bx",
    aws_content_delivery_bucket_region: "eu-west-1",
    aws_content_delivery_url: "https://d2juwaru0sf6gi.cloudfront.net",
    aws_user_files_s3_bucket:
      "bookingsstack-contentdeliverybucketf75a2430-l9tx86b1ow43",
    aws_user_files_s3_bucket_region: "eu-west-1",
    aws_staging_s3_bucket:
      "bookingsstack-userassetsbucket8b59e294-1jm02blji5sa3",
  },
  test: {
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id:
      "eu-west-1:2c443943-f342-4166-89fb-0eee760ed368",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_AnsIoARXd",
    aws_user_pools_web_client_id: "n1jv3d13vbr55l4tr2a0cn9j2",
    mandatorySignIn: false,
    oauth: {},
    aws_appsync_graphqlEndpoint:
      "https://pvwq3s2z7bh43phr6rmxc5kp4i.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_content_delivery_bucket:
      "portalstack-hostingbucket5dac2127-1axvp8asfj2vr",
    aws_content_delivery_bucket_region: "eu-west-1",
    aws_content_delivery_url: "https://d2juwaru0sf6gi.cloudfront.net",
    aws_user_files_s3_bucket:
      "bookingsstack-contentdeliverybucketf75a2430-w6l6tlju43j",
    aws_user_files_s3_bucket_region: "eu-west-1",
    aws_staging_s3_bucket:
      "routesstack-deliverystagingbucketc0b89a53-2lldr6luqi42",
  },
  prod: {
    aws_project_region: "eu-west-1",
    aws_cognito_identity_pool_id:
      "eu-west-1:d8de68d3-4c25-4d2e-be46-d1026a982ea0",
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: "eu-west-1_MWrlFVZKs",
    aws_user_pools_web_client_id: "4dh1h5kr22efi1tgtlo231dgla",
    mandatorySignIn: false,
    oauth: {},
    aws_appsync_graphqlEndpoint:
      "https://fsbhzxoxofh4dbhjompvalc5em.appsync-api.eu-west-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-west-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_content_delivery_bucket:
      "portalstack-hostingbucket5dac2127-9mnmhavqfdbs",
    aws_content_delivery_bucket_region: "eu-west-1",
    aws_content_delivery_url: "https://d2juwaru0sf6gi.cloudfront.net",
    aws_user_files_s3_bucket:
      "bookingsstack-contentdeliverybucketf75a2430-11m0n8oy1nro1",
    aws_user_files_s3_bucket_region: "eu-west-1",
    aws_staging_s3_bucket:
      "routesstack-deliverystagingbucketc0b89a53-2lldr6luqi42",
  },
};

export default awsConfig[
  process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : process.env.NODE_ENV.substring(0, 3)
];
