import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export default function Alerts(props) {
    return(
        <div>
            <Snackbar open={props.error} autoHideDuration={4000} onClose={() => { props.setError(null); }}>
                <Alert severity="error">{props.error}</Alert>
            </Snackbar>

            <Snackbar open={props.success} autoHideDuration={4000} onClose={() => { props.setSuccess(null); }}>
                <Alert severity="success">{props.success}</Alert>
            </Snackbar>
        </div>
    )
}