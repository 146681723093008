import React from "react";
import API, { graphqlOperation } from "@aws-amplify/api";

import * as queries from "./graphql/queries";

export function getOrganization(tenantId) {
  return API.graphql(
    graphqlOperation(queries.getOrganization, {
      pk: "ORG#" + tenantId,
      sk: "PROFILE",
    })
  );
}
