import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import { getOrganization } from "../services/users";

export default function Redirect(props) {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user) => {
        if (user) {
          getOrganization(user.attributes["custom:tenantIdList"].split(",")[0])
            .then((res) => {
              window.location.href = `https://${res.data.listUsers[0].alias}${
                window.location.hostname.toLowerCase().split(".")[1] !==
                "gowizi"
                  ? "." + window.location.hostname.toLowerCase().split(".")[1]
                  : ""
              }.gowizi.com/connectors?shop=${new URLSearchParams(
                props.location.search
              ).get("shop")}&access_token=${new URLSearchParams(
                props.location.search
              ).get("access_token")}&scope=${new URLSearchParams(
                props.location.search
              ).get("scope")}`;
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return null;
}
