import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Bars from '../components/Misc/Bars';
import Grid from '@material-ui/core/Grid';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <MaterialLink color="inherit" href="#">
        GoWizi
      </MaterialLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function Privacy(props) {

  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.appBarSpacer} />
      <CssBaseline />
      <Bars pageName="Política de Privacidade" />
      <Grid container spacing={0}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography
            variant="h4"
            id="tableTitle"
            component="div"
            style={{ textAlign: "center" }}
          >
            Política de Privacidade
          </Typography>
          <br />
          <br />
          <div style={{ textAlign: "left" }}>
            Cumprindo o disposto no Regulamento Geral de Proteção de Dados, esta política de privacidade descreve o modo como a nossa organização coleta, utiliza e gere os dados pessoais coletados e tratados, durante a utilização das aplicações e sites GoWizi.
            <br />
            <br />
            <br />

            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Informações recolhidas
            </Typography>
            Processamos e recolhemos diretamente os seguintes dados pessoais:

            <ol>
              <li>Informação de identificação pessoal (Nome, endereço de email, número de telefone, morada de entrega, etc.);</li>
              <li>Número de identificação fiscal.</li>
            </ol>

            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Processo de recolha de dados
            </Typography>
            Ao subscrever os nossos serviços, o utilizador disponibiliza parte da informação que recolhemos e tratamos. Coletamos e processamos dados pessoais sempre que os nossos utilizadores:

            <ol>
              <li>Subscreverem uma das nossas newsletter;</li>
              <li>Efetuarem um novo registo ou criam uma nova encomenda;</li>
              <li>Voluntariamente, completarem um questionário ou entrarem em contacto com a GoWizi através de qualquer um dos nossos canais de comunicação;</li>
              <li>Utilizarem ou visualizarem o nosso sites ou aplicações através dos cookies do seu browser.</li>
            </ol>

            Poderemos receber dados pessoais, indiretamente, através das seguintes fontes de informação:


            <ol>
              <li>Informação pública no Google Maps.</li>
            </ol>

            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Processamento de dados pessoais
            </Typography>
            Recolhemos informações pessoais com o propósito de:

            <ol>
              <li>Processar encomendas e gerir a conta de cliente;</li>
              <li>Garantir que as encomendas/recolhas são entregues/recolhidas na hora correta e com a melhor qualidade;</li>
              <li>Gerar e enviar faturas, de acordo com as requisições.</li>
            </ol>

            Em situação de concordância, facilitamos a partilha de informação entre consumidores e produtores, para que ambos possam beneficiar de uma maior transparência relativamente à procura e oferta dos bens comercializados.

            Quando são executadas transações, dados pessoais poderão ser enviados para agências de referência de crédito, prevenindo compras fraudulentas. De igual forma, a GoWizi pode, também, utilizar dados que estas agências nos providenciarem.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Proteção de dados pessoais
            </Typography>
            Mantemos uma infraestrutura de tecnologias de informação que, ativamente, garante a segurança dos seus dados em quatro campos de ação: prevenção, deteção, resposta e remediação. Para além disso, todos os dados guardados são encriptados utilizando técnicas de criptografia de alta fiabilidade.
            Todos os dados pessoais são armazenados, até que se verifique um período de inatividade (em que o utilizador não entra no sistema) superior a seis meses. Após uma inatividade de dois meses, os utilizadores receberão informações caso se verifique uma inatividade superior a seis meses. Quando a marca dos seis meses for atingida, os acessos serão revogados e todos os dados pessoais do utilizador serão apagados.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Marketing
            </Typography>
            Pretendemos partilhar informação relativa a todos os produtos e serviços GoWizi, que acreditamos ser do interesse do utilizador, incluindo os provenientes das nossas empresas parceiras.
            O utilizador tem o direito de, a qualquer momento, solicitar o término das comunicações para efeitos de marketing, enviando um breve email para <a href="mailto:help@gowizi.com">help@gowizi.com</a> .
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Direitos de proteção de dados
            </Typography>
            Aos membros da plataforma GoWizi são-lhes reservados os seguintes direitos de proteção de dados:

            <ol>
              <li>Direito de acesso – Os utilizadores têm o direito de nos solicitar cópias dos próprios dados pessoais. Este serviço pode estar sujeito ao pagamento de uma taxa de operação.</li>
              <li>Direito de retificação – Os utilizadores têm o direito de solicitar correções a qualquer informação que acreditem ser incorreta. Têm, também, o direito de requisitar que seja completada, informação que se acredite ser incompleta.</li>
              <li>Direito de eliminação – Os utilizadores têm o direito de solicitar que a eliminação de todos os seus dados pessoais.</li>
              <li>Direito de restringir o tratamento – Os utilizadores têm o direito de solicitar que o tratamento de todos os seus dados pessoais seja restrito.</li>
              <li>Direito de oposição ao tratamento – Os utilizadores têm o direito de solicitar que nenhum dos seus dados pessoais seja processado ou tratado.</li>
              <li>Direito de portabilidade dos dados – Os utilizadores têm o direito de solicitar que todos os seus dados pessoais sejam transferidos para outra organização ou diretamente para os mesmos.</li>
            </ol>

            A partir do momento em que alguma destas requisições ocorre, garantimos uma resposta no prazo de um mês. Caso o utilizador desejar exercer algum destes direitos, contactar via email: <a href="mailto:help@gowizi.com">help@gowizi.com</a>
            <br /><br />Ou através de um telefonema: (+351) 800 180 230
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Sobre cookies
            </Typography>
            Cookies são ficheiros de texto guardados no equipamento (computador, tablet, telemóvel, etc.) com o objetivo de recolher informação de navegação e comportamento dos utilizadores na internet. Sempre que se visita as nossas plataformas digitais, informação pode ser automaticamente processada através de cookies ou tecnologia semelhante.
            <br /><br />Para mais informação, consultar:  <a href="https://allaboutcookies.org/" target="_blank" rel="noopener noreferrer">allaboutcookies.org</a> .
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              A utilização de cookies
            </Typography>
            Utilizamos cookies para oferecer a melhor experiência aos nossos utilizadores, incluindo:

            <ol>
              <li>Manter a sessão ativa;</li>
              <li>Melhorar a experiência de utilização.</li>
            </ol>

            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Tipos de cookies utilizados
            </Typography>
            Nas nossas plataformas, utilizamos apenas cookies funcionais. Estes cookies desempenham funções cruciais para uma boa experiência de utilização, tais como manter os utilizadores autenticados, lembrar-nos das suas preferências, idioma, localização, entre outros.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Gestão de cookies
            </Typography>
            Qualquer utilizador tem a possibilidade de restringir, no seu navegador web, a utilização de cookies - o site acima detalha a forma como executar este processo. No entanto, em certos casos, algumas funcionalidades das plataformas poderão apresentar problemas de incompatibilidade.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Políticas de privacidade de outros sites e aplicações
            </Typography>
            Nas plataformas GoWizi são disponibilizados ligações para outros sites. A nossa política de privacidade aplica-se única e exclusivamente ao nosso site e aplicações. Por este motivo, se o utilizador navegar para uma ligação externa, disponibilizada nas nossas plataformas, aconselhamos a leitura atenta das políticas de privacidade respectivas.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Alterações à nossa política de privacidade
            </Typography>
            Revemos muito frequentemente a nossa política de privacidade e publicamos as alterações que dela surgem nas nossas plataformas. Esta política de privacidade foi atualizada, pela última vez, no dia 27 de Agosto de 2021.
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Como nos contactar
            </Typography>
            Caso surja alguma questão relacionada com a nossa política de privacidade, a informação que recolhemos e tratamos, ou se se pretenda exercer qualquer tipo de direito de proteção de dados, por favor entrar em contacto.
            <br /><br />Envie-nos um email: <a href="mailto:help@gowizi.com">help@gowizi.com</a>
            <br /><br />Ou telefone: (+351) 962 064 301
            <br />
            <br />
            <Typography
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Como contactar as autoridades responsáveis
            </Typography>
            Na hipótese de o utilizador pretender reportar um incidente, apresentar uma reclamação ou achar que não procedemos da melhor forma, reservamos-lhe o direito de entrar em contacto com a Comissão Nacional de Protecção de Dados (CNPD).
            <br /><br />
            <b>Endereço:</b><br />
            Comissão Nacional de Protecção de Dados<br />
            Av. D. Carlos I, 134 - 1.º<br />
            1200-651 Lisboa, Portugal<br />
            <b>Telefone:</b> (+351) 213 928 400<br />
            <b>Email:</b>  <a href="mailto:geral@cnpd.pt">geral@cnpd.pt</a>

          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </main>
  );
}