import React, { useState, useEffect } from "react";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ConfirmSignUp from "./ConfirmSignUp";
import AuthAlerts from "./AuthAlerts";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AuthState } from "@aws-amplify/ui-components";

import { Amplify, Auth } from "aws-amplify";
import awsConfig from "../../aws-exports";

import { makeStyles } from "@material-ui/core/styles";

import { OrganizationContext } from "../OrganizationContext";
import { getOrganization } from "../../services/users";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Authenticator = (HocComponent) => {
  const AuthFunction = () => {
    const classes = useStyles();
    const [organization, setOrganization] = useState();
    const [authState, setAuthState] = useState("");
    const [user, setUser] = useState();

    useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            getOrganization(user.attributes["custom:tenantId"]).then((res) => {
              if (res.data.listUsers) {
                const organizationObject = res.data.listUsers[0];
                setOrganization(organizationObject);
              }
            });
            setUser(user);
            changeAuthState(AuthState.SignedIn);
          }
        })
        .catch((e) => {
          changeAuthState(AuthState.SignIn);
        });
    }, []);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [code, setCode] = React.useState();
    const [signingIn, setSigningIn] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);
    const [rememberLogin, setRememberLogin] = React.useState(false);
    const [phone_number, setPhoneNumber] = React.useState("+");
    const [loading, setLoading] = React.useState(false);

    const handleUsernameChange = (event) => {
      event.preventDefault();
      setUsername(event.target.value);
      setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
      event.preventDefault();
      setPassword(event.target.value);
    };

    const handlePhoneNumberChange = (value) => {
      setPhoneNumber("+".concat(value));
    };

    const handleVCChange = (event) => {
      event.preventDefault();
      setCode(event.target.value);
    };

    const handleSignIn = (event) => {
      event.preventDefault();

      rememberLogin
        ? Amplify.configure({ ...awsConfig, storage: localStorage })
        : Amplify.configure({ ...awsConfig, storage: sessionStorage });

      setSigningIn(true);
      Auth.signIn(username, password)
        .then((user) => {
          setUser(user);
          setSigningIn(false);
          changeAuthState(AuthState.SignedIn);
        })
        .catch((e) => {
          setSigningIn(false);
          switch (e.code) {
            case "UserNotFoundException":
              setError("Nome de utilizador ou palavra passe errados.");
              break;
            case "NotAuthorizedException":
              setError("Nome de utilizador ou palavra passe errados.");
              break;
            case "UserNotConfirmedException":
              changeAuthState(AuthState.ConfirmSignUp);
              resendCode();
              break;
            default:
              break;
          }
        });
    };

    const handleSignUp = (event) => {
      event.preventDefault();
      setLoading(true);

      Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      })
        .then((user) => {
          setUser(user);
          event.preventDefault();
          changeAuthState(AuthState.ConfirmSignUp);
        })
        .catch((e) => {
          event.preventDefault();
          setLoading(false);
          changeAuthState(AuthState.SignUp);

          switch (e.code) {
            case "UsernameExistsException":
              setError("Username não disponível.");
              break;
            case "InvalidPasswordException":
              setError("A password deve ter pelo menos 8 caracteres.");
              break;
            case "InvalidParameterException":
              setError("A password deve ter pelo menos 8 caracteres.");
              break;
            default:
              break;
          }
        });
    };

    const confirmHelper = () => {
      setSigningIn(true);
      Auth.signIn(username, password)
        .then((user) => {
          setUser(user);
          setSigningIn(false);
          setConfirming(false);
          changeAuthState(AuthState.SignedIn);
        })
        .catch((e) => {
          setConfirming(false);
          setSigningIn(false);
          switch (e.code) {
            case "UserNotFoundException":
              setError("Nome de utilizador ou palavra passe errados.");
              break;
            case "NotAuthorizedException":
              setError("Nome de utilizador ou palavra passe errados.");
              break;
            case "UserNotConfirmedException":
              changeAuthState(AuthState.ConfirmSignUp);
              resendCode();
              break;
            default:
              break;
          }
        });
    };

    const handleConfirm = (event) => {
      event.preventDefault();
      setLoading(false);
      setConfirming(true);

      Auth.confirmSignUp(username, code)
        .then(() => {
          setTimeout(confirmHelper, 2000);
        })
        .catch((e) => {
          setConfirming(false);
          switch (e.code) {
            case "CodeMismatchException":
              setError("Combinação de username/código errada..");
              break;
            case "UserNotFoundException":
              setError("Combinação de username/código errada.");
              break;
            default:
              setError("Erro desconhecido.");
          }
          changeAuthState(AuthState.ConfirmSignUp);
        });
    };

    const handleForgotPassword = (event) => {
      event.preventDefault();
      // Send Code
      Auth.forgotPassword(username)
        .then((data) => {
          setSuccess("Código enviado com sucesso.");
        })
        .catch((e) => {
          setError("Ocorreu um erro - por favor verifica o username.");
        });
    };

    const handleSetNewPassword = (event) => {
      event.preventDefault();

      Auth.forgotPasswordSubmit(username, code, password)
        .then(() => {
          setSuccess("Password alterada com sucesso.");
          changeAuthState(AuthState.SignIn);
        })
        .catch(() => {
          setError("Ocorreu um erro - por favor pede um novo código.");
        });
    };

    const resendCode = () => {
      Auth.resendSignUp(username)
        .then(() => {
          setSuccess("Código enviado com sucesso!");
        })
        .catch((e) => {
          switch (e.code) {
            case "LimitExceededException":
              setError(
                "Demasiadas tentativas de recuperação detectadas - por favor tenta mais tarde."
              );
              break;
            default:
              break;
          }
        });
    };

    const changeAuthState = (message) => {
      setAuthState(message);
    };

    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    const getLoginStatusPage = () => {
      switch (authState) {
        case AuthState.SignedIn:
          if (user) {
            return (
              <div>
                <HocComponent />
              </div>
            );
          } else {
            changeAuthState(AuthState.SignIn);
            return null;
          }
        case AuthState.SignIn:
          return (
            <div>
              <SignIn
                handleUsernameChange={handleUsernameChange}
                handlePasswordChange={handlePasswordChange}
                changeAuthState={changeAuthState}
                onSubmit={handleSignIn}
                username={username}
                password={password}
                rememberLogin={rememberLogin}
                setRememberLogin={setRememberLogin}
              />

              <AuthAlerts
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
              />

              <Backdrop className={classes.backdrop} open={signingIn}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          );
        case AuthState.SignUp:
          return (
            <div>
              <SignUp
                handleUsernameChange={handleUsernameChange}
                handlePasswordChange={handlePasswordChange}
                handlePhoneNumberChange={handlePhoneNumberChange}
                changeAuthState={changeAuthState}
                onSubmit={handleSignUp}
                username={username}
                email={email}
                password={password}
                phoneNumber={phone_number}
              />
              <AuthAlerts
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
              />
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          );
        case AuthState.ForgotPassword:
          return (
            <div>
              <ForgotPassword
                handleUsernameChange={handleUsernameChange}
                handlePasswordChange={handlePasswordChange}
                handleVCChange={handleVCChange}
                changeAuthState={changeAuthState}
                onSubmit={handleSetNewPassword}
                forgotPassword={handleForgotPassword}
                username={username}
                password={password}
                code={code}
              />
              <AuthAlerts
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
              />
            </div>
          );
        case AuthState.ConfirmSignUp:
          return (
            <div>
              <ConfirmSignUp
                handleUsernameChange={handleUsernameChange}
                handleVCChange={handleVCChange}
                changeAuthState={changeAuthState}
                onSubmit={handleConfirm}
                username={username}
                code={code}
                resendCode={resendCode}
              />
              <AuthAlerts
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
              />

              <Backdrop className={classes.backdrop} open={confirming}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          );
        default:
          return null;
      }
    };

    return getLoginStatusPage();
  };

  return AuthFunction;
};

export default Authenticator;
